import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { camelCase, startCase } from 'lodash';
import { paths } from 'services/route';
import state from 'data/state';
import isEmpty from 'utils/isEmpty';
import iconType from 'utils/iconType';
import * as VenueType from 'views/venueType/config';
import * as Contact from 'views/contact/config';
import * as Market from 'views/market/config';
import * as Neighborhood from 'views/neighborhood/config';
import * as Campaign from 'views/campaign/config';
import * as Route from 'views/route/config';
import * as Item from 'views/item/config';
import { exportAgreement } from 'views/venueAgreement/config';
import { agreementStatus } from 'views/routeSheet/config';

export const status = {
  ACTIVE: '',
  INACTIVE: '',
};
const statusItems = Object.keys(status).map((key) => {
  status[key] = startCase(camelCase(key));
  return status[key];
});

export const source = {
  WNDW: 'WNDW',
  GOOGLE: 'Google',
};

export const model = 'venue';
export const label = 'Venue';
export const apiPath = '/venues';
export const routePath = () => paths.routes.venues;
export const schema = {
  id: {
    label: 'ID',
    required: true,
    primaryKey: true,
    grid: { hide: false },
    form: {
      hideAdd: true,
      info: true,
    },
  },
  campaignPlanVenue: {
    label: 'Selected',
    type: 'boolean',
    query: true,
    grid: {
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Name',
    required: true,
    grid: {
      minWidth: 150,
    },
  },
  fk_typeId: {
    label: 'Type',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'type',
      itemsUrl: () => VenueType.apiPath,
    },
  },
  'type.type': {
    label: 'Type',
    grid: {},
    form: {
      hide: true,
    },
  },
  fk_marketId: {
    label: 'Market',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: (data, { dataParent, modelParent }) => `${Market.apiPath}${modelParent === Campaign.model ? `?campaignId=${dataParent.id}` : modelParent === Route.model ? `?routeId=${dataParent.id}` : ''}`,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          await fields.find(({ key }) => key === 'fk_neighborhoodId')?.form.initItems({ fk_marketId: item.value });
          setFieldValue('fk_neighborhoodId', '');
          await fields.find(({ key }) => key === 'fk_routeId')?.form.initItems({ fk_marketId: item.value });
        }
      },
    },
  },
  'market.name': {
    label: 'Market',
    grid: {},
    form: {
      hide: true,
    },
  },
  fk_neighborhoodId: {
    label: 'Neighborhood',
    //required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: (data) => data && data.fk_marketId ? `${Neighborhood.apiPath}?marketId=${data.fk_marketId}` : null,
      //itemsUrl: (data, { dataParent, modelParent }) => data && data.fk_marketId ? `${Neighborhood.apiPath}?marketId=${data.fk_marketId}${modelParent === Campaign.model ? `&campaignId=${dataParent.id}` : ''}` : null,
    },
  },
  'neighborhood.name': {
    label: 'Neighborhood',
    grid: {},
    form: {
      hide: true,
    },
  },
  fk_routeId: {
    label: 'Route',
    grid: {
      detail: () => ({
        key: 'route._id',
        ...Route,
      }),
    },
    form: {
      type: 'autocomplete',
      primaryKey: '_id',
      valueKey: 'id',
      labelKey: 'id',
      itemsUrl: (data) => data && data.fk_marketId ? `${Route.apiPath}?marketId=${data.fk_marketId}` : null,
    },
  },
  status: {
    label: 'Status',
    form: {
      type: 'autocomplete',
      items: statusItems,
      defaultValue: statusItems[0],
    },
  },
  id_primaryContact: {
    label: 'Primary Contact',
    form: {
      //hide: true,
      hideAdd: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      colBreak: true,
      model: () => Contact.model,
      itemsUrl: (data) => `${Contact.apiPath}?venueId=${data?.id}`,
      onChange: async ({ item, setFieldValue }) => {
        if (item) {
          const { data } = await axios.get(`${Contact.apiPath}/${item.value}`);
          ['phone'].forEach((key) => setFieldValue(key, data[key]));
        }
      },
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  'contact.nameFull': {
    label: 'Primary Contact',
    form: {
      hide: true,
    },
  },
  streetNumber: {
    label: 'Street Number',
    required: true,
    grid: {
      hide: true,
    },
    form: {
      //hide: true,
      mapUpdate: true,
    },
  },
  streetName: {
    label: 'Street Name',
    required: true,
    grid: {
      hide: true,
    },
    form: {
      //hide: true,
    },
  },
  address: {
    label: 'Address',
    grid: {
      minWidth: 150,
    },
    form: {
      hide: true,
    },
  },
  city: {
    label: 'City',
    required: true,
    form: {
      //hide: true,
    },
  },
  state: {
    label: 'State',
    required: true,
    form: {
      //hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  zip: {
    label: 'Zip',
    // type: 'number',
    // required: true,
    exportData: true,
    grid: {},
    form: {
      //hide: true,
      col: 2,
    },
  },
  county: {
    label: 'County',
    exportData: true,
    grid: {},
    form: {
      info: true,
    },
  },
  phone: {
    label: 'Phone',
    form: {
      //hide: true,
    },
  },
  contactEmail: {
    type: 'email',
    label: 'Email',
    grid: {
      minWidth: 150,
    },
    form: {
      hide: true,
    },
  },
  latitude: {
    label: 'Latitude',
    exportData: true,
    form: {
      info: true,
    },
  },
  longitude: {
    label: 'Longitude',
    exportData: true,
    form: {
      info: true,
    },
  },
  facingDirection: {
    label: 'Facing',
    exportData: true,
    form: {
      info: true,
    },
  },
  geopathId: {
    label: 'Geopath ID',
    exportData: true,
    form: {
      info: true,
    },
  },
  // photo: {
  //   label: 'Photo',
  //   type: 'image',
  //   form: {
  //     colBreak: true,
  //     hideAdd: true,
  //   },
  // },
  map: {
    label: 'Location',
    form: {
      map: true,
      colBreak: true,
    },
  },
  'distributionVenue.item.name': {
    label: 'Item',
    grid: {
      hide: true,
      detail: () => ({
        key: 'distributionVenue.item.id',
        ...Item,
      }),
    },
    form: {
      hide: true,
    },
  },
  distributionPossibleLeave: {
    label: 'Possible Leave',
    type: 'boolean',
    // grid: {
    //   width: 50,
    // },
    form: {
      hide: true,
    },
  },
  distributionActual: {
    label: 'Photo Posted',
    type: 'boolean',
    form: {
      hide: true,
    },
  },
  distributionPhoto: {
    label: 'Photo',
    type: 'boolean',
    form: {
      hide: true,
    },
  },
  distributionPhotoAudit: {
    label: 'Photo (Audit)',
    type: 'boolean',
    form: {
      hide: true,
    },
  },
  distributionRefused: {
    label: 'Refused',
    type: 'boolean',
    form: {
      hide: true,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      hide: true,
    },
  },
  agreementDate: {
    label: 'Agreement',
    grid: {
      hide: true,
      cellClass: 'linked',
      cellRenderer: ({ params, dataParent }) =>
        params.value ?
          <>
            <i
              className={`fa fa-${iconType.EXPORT.className} icon`}
              onClick={() => exportAgreement({
                venueId: params.data.id,
                campaignId: dataParent.id,
              })}
            />
            {moment(params.value).format('M/D/YYYY')}
          </> : '-',
    },
    form: {
      hide: true,
    },
  },
  agreementStatus: {
    label: 'Agreement',
    grid: {
      hide: true,
      cellClass: 'linked',
      cellRenderer: ({ params, dataParent }) =>
        params.value ?
          <>
            {params.value === agreementStatus.COMPLETED && <i
              className={`fa fa-${iconType.EXPORT.className} icon`}
              onClick={() => exportAgreement({
                venueId: params.data.id,
                campaignId: dataParent.id,
              })}
            />}
            {params.value}
          </> : '-',
    },
    form: {
      hide: true,
    },
  },
  upCurrent: {
    label: 'Up Current',
    type: 'boolean',
    grid: {
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  auditStatus: {
    label: 'Audit Status',
    grid: {
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    grid: {},
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
