import * as Route from 'services/route';
import * as ClientType from 'views/clientType/config';
import * as Contact from 'views/contact/config';
import state from 'data/state';

export const model = 'client';
export const label = 'Company';
export const apiPath = '/clients';
export const routePath = () => Route.paths.crm.companies;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  name: {
    label: 'Name',
    required: true,
    grid: {
      minWidth: 150,
    },
  },
  fk_typeId: {
    label: 'Type',
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'type',
      itemsUrl: () => ClientType.apiPath,
    },
  },
  'type.type': {
    label: 'Type',
    grid: {},
    form: {
      hide: true,
    },
  },
  status: {
    label: 'Status',
    form: {
      type: 'autocomplete',
      items: [ 'Lead', 'Proposed', 'Active', 'Inactive', 'Dead' ],
    },
  },
  id_primaryContact: {
    label: 'Primary Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ id }) => `${Contact.apiPath}?clientId=${id}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  'contact.nameFull': {
    label: 'Primary Contact',
    grid: {
      minWidth: 150,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
    form: {
      hide: true,
    },
  },
  address: {
    label: 'Address',
    form: {
      hide: true,
    },
    /* form: {
      colBreak: true,
    }, */
  },
  city: {
    label: 'City',
    grid: {
      minWidth: 125,
    },
    form: {
      hide: true,
    },
  },
  state: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  zip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  email: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  phone: {
    label: 'Phone',
    form: {
      hide: true,
    },
  },
  mobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  fax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  id_billingContact: {
    label: 'Billing Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ id }) => `${Contact.apiPath}?clientId=${id}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  billingAddress: {
    label: 'Address',
    form: {
      hide: true,
    },
  },
  billingCity: {
    label: 'City',
    form: {
      hide: true,
    },
  },
  billingState: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  billingZip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  billingEmail: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  billingPhone: {
    label: 'Phone',
    form: {
      hide: true,
    },
  },
  billingMobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  billingFax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  dateLastActivity: {
    type: 'date',
    label: 'Latest Activity',
    grid: {
      minWidth: 140,
    },
    form: {
      hide: true,
    },
  },
  owner: {
    label: 'Owner',
    grid: {
      minWidth: 125,
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  dateTransfer: {
    type: 'date',
    label: 'Latest Transfer',
    grid: {
      //minWidth: 140,
      hide: true,
    },
    form: {
      hide: true,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
    },
  },
  tags: {
    label: 'Tags',
    grid: {},
    form: {
      type: 'tag',
      colBreak: true,
      colWidth: 2,
    },
  },
  createdBy: {
    label: 'Created By',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    form: {
      info: true,
    },
  },
  modifiedBy: {
    label: 'Modified By',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
